.Layout {
	text-align: center;
}

.Layout .hidden {
	display: none !important;
}

.Layout-logo {
	height: 40vmin;
}

.Layout-header {
	color: #A5AFB5;
}

.Layout-header h1 {
	text-transform: uppercase;
}

.Layout-content {
	margin-top: 1rem;
	font-family: 'Roboto', sans-serif;
}

.Layout-content h1,
.Layout-content h2,
.Layout-content h3,
.Layout-content h4,
.Layout-content h5 {
	font-family: BebasNeueRegular;
}

.bg-dark {
	background-color: #4B5E6F !important;
}

.navbar-dark .navbar-brand {
	color: #DBDFE1;
}

.navbar .nav-link,
.navbar .btn-link {
	color: #DBDFE1;
}

.navbar .nav-link:hover,
.navbar .btn-link:hover {
	color: #FFFFFF;
}

.Layout-header .navbar .login {
	position: relative;
	display: inline-flex;
}

.Layout-header .navbar .login .popover {
	top: 40px;
}
