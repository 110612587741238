
.Homepage .App-logo {
	height: 40vmin;
	float: left;
}

.Homepage h3 {
	margin-top: 100px;
}

.Homepage .history {
	font-style: italic;
	border: 1px solid #ccc;
	padding: 35px 40px;
	background-color: #ddd;
}

.Homepage .clear {
	clear: both;
}

.Homepage .winter-challenge {
	float: right;
}

.Homepage .strava-widget {
	width: 400px;
	height: 500px;
	border: 0;
	overflow: hidden;
}
