
.Modal {
	display: none;
}

.Modal.show {
	display: block;
}

.Modal .modal-body {
	overflow-y: auto;
	max-height: 90vh;
}
