
.QR {
	position: relative;
	margin-right: 3px;
}

.QR .btn {
	min-width: 18px;
}

.QR .btn .qr-icon {
	width: 20px;
	height: 20px;
}

.QR .qr-code {
	margin: 20px 10px;
}
