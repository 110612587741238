
@font-face {
	font-family: BebasNeueRegular;
	src: url('./Fonts/BebasNeue-Regular.eot');
	src: url('./Fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
		url('./Fonts/BebasNeue-Regular.woff2') format('woff2'),
		url('./Fonts/BebasNeue-Regular.woff') format('woff'),
		url('./Fonts/BebasNeue-Regular.ttf') format('truetype'),
		url('./Fonts/BebasNeue-Regular.otf') format("opentype");
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, BebasNeueRegular, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #DBDFE1;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.icon-external {
	font-size: 0.6em;
	opacity: 0.4;
}

a[target="_blank"] {
	text-decoration: none;
	color: rgb(107, 79, 34);
}

a[target="_blank"]:hover {
	text-decoration: underline;
}
