
.PlayerLinkModal-modal {
	display: block;
	background-color: rgba(0, 0, 0, 0.5);
	font-family: 'Roboto', sans-serif;
}

.PlayerLinkModal-modal .player-row .player-link-button {
	opacity: 0.2;
}

.PlayerLinkModal-modal .player-row:hover .player-link-button {
	opacity: 1;
}

.PlayerLinkModal-modal .modal-body {
	overflow-y: auto;
	max-height: calc(100vh - 120px);
}
