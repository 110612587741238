
.Transactions .Participants {
	position: relative;
	margin-right: 3px;
}

.Transactions .Participants .badge {
	min-width: 18px;
}

.Transactions .Participants .ParticipantsPopover {
	display: none;
	position: absolute;
	top: 20px;
	left: -5px;
}

.Transactions .Participants .ParticipantsPopover .popover-body {
	white-space: nowrap;
}

.Transactions .Participants:hover .ParticipantsPopover {
	display: block;
}
