
.Match-attendees {
	margin-top: 10px;
}

.Match-cards {
	margin-bottom: 50px;
}

.Match-unresponded {
	margin-bottom: 80px;
}

.Match-changeResponse {
	margin-top: 80px;
}
